"use client";

import React, { useState, useEffect } from "react";
import {
  Github,
  Linkedin,
  Mail,
  ExternalLink,
  Moon,
  Sun,
  ChevronRight,
  Code,
  Coffee,
  BookOpen,
  Award,
  X,
  Download,
  Menu,
} from "lucide-react";
const projects = [
  {
    title: "Website Analysis Tool",
    description:
      "A tool that helps website owners understand and improve their site’s performance, accessibility, and SEO. It provides clear insights through Lighthouse scores, checks for mobile-friendliness, and tracks progress over time. Built using React and Node.js for a smooth, fast experience, with Redis for caching and Docker for easy deployment.",
    tech: ["React", "Node.js", "Mongodb", "Docker"],
    link: "https://github.com",
    demo: "https://reflect-dun.vercel.app",
  },
  {
    title: "Feathery.in",
    description:
      "Feathery.in is a personal branding platform designed to help individuals document their learning journeys, connect with like-minded people, and cultivate a mindset of continuous growth. By offering a streamlined process to share progress, define unique value propositions, and enhance expertise, Feathery.in empowers users to build a strong online presence and engage with vibrant communities.",
    tech: ["React", "Node.js", "MongoDB"],
    link: "https://feathery.in",
    demo: "https://feathery.in",
  },
  {
    title: "NamesApp.com",
    description:
      "NamesApp.com is a platform for discovering unique and creative domain names. I developed this tool to make it easy for users to explore domain options with a streamlined search experience. Built with a user-friendly interface, NamesApp.com helps individuals and businesses find the perfect domain for their needs.",
    tech: ["React", "Node.js", "MongoDB"],
    link: "https://namesapp.com",
    demo: "https://namesapp.com",
  },
  //   {
  //     title: "Task Management App",
  //     description:
  //       "Mobile-first productivity app with real-time collaboration and offline support, built using React Native and Firebase. This reflects my experience in designing impactful UIs and mobile-first development.",
  //     tech: ["React Native", "Firebase", "Redux", "TypeScript"],
  //     link: "https://github.com",
  //     demo: "https://demo.example.com",
  //   },
];

const skills = {
  Frontend: ["React", "Angular", "TypeScript", "Tailwind CSS"],
  Backend: ["Node.js", "Python", "Django", "MongoDB"],
  DevOps: ["Docker", "VM"],
  Tools: ["Git", "VS Code", "Figma", "Postman", "Linux"],
};

const experience = [
  {
    role: "Web Developer",
    company: "pintube.com",
    period: "April 2023 - Present",
    achievements: [
      "Developed scalable web applications using Angular and Node.js.",
      "Designed user-friendly, impactful UI components for improved user experience.",
      "Gained hands-on experience in product management, product design, and teamwork.",
    ],
  },
  {
    role: "Full Stack Developer",
    company: "Innovace Studio",
    period: "August 2024 - September 2024",
    achievements: [
      "Worked on full stack development projects, enhancing my skills in React Native and Django.",
      "Contributed to designing and developing key features for web applications.",
    ],
  },
  {
    role: "Backend Developer",
    company: "ZAVX",
    period: "February 2024 - April 2024",
    achievements: [
      "Created and integrated Alexa skills using AWS Lambda functions.",
      "Developed and optimized Node.js backend APIs for seamless performance.",
    ],
  },
];

const NavBar = ({
  isDarkMode,
  toggleDarkMode,
  activeSection,
  setActiveSection,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleItemClick = (item) => {
    setActiveSection(item);
    setIsMenuOpen(false);
  };

  const navItems = ["Projects", "Experience", "Skills", "Contact"];

  return (
    <nav
      className={`fixed top-0 w-full bg-white/80 dark:bg-gray-950/80 backdrop-blur-lg z-50 transition-all duration-300 
      ${
        isScrolled
          ? "border-b border-gray-200 dark:border-gray-800 shadow-sm"
          : ""
      }`}
    >
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <a
            href="#"
            className="text-xl font-bold text-gray-900 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition-colors"
          >
            Aryan Thakor
          </a>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navItems.map((item) => (
              <a
                key={item}
                href={`/#${item.toLowerCase()}`}
                onClick={() => handleItemClick(item.toLowerCase())}
                className={`text-sm font-medium transition-colors duration-200
                  ${
                    activeSection === item.toLowerCase()
                      ? "text-blue-600 dark:text-blue-400"
                      : "text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                  }`}
              >
                {item}
              </a>
            ))}

            {/* Theme Toggle Button */}
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
              aria-label={
                isDarkMode ? "Switch to light mode" : "Switch to dark mode"
              }
            >
              {isDarkMode ? (
                <Sun className="h-5 w-5 text-gray-300" />
              ) : (
                <Moon className="h-5 w-5 text-gray-600" />
              )}
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="flex items-center space-x-4 md:hidden">
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
              aria-label={
                isDarkMode ? "Switch to light mode" : "Switch to dark mode"
              }
            >
              {isDarkMode ? (
                <Sun className="h-5 w-5 text-gray-300" />
              ) : (
                <Moon className="h-5 w-5 text-gray-600" />
              )}
            </button>
            <button
              onClick={toggleMenu}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6 text-gray-600 dark:text-gray-300" />
              ) : (
                <Menu className="h-6 w-6 text-gray-600 dark:text-gray-300" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out ${
            isMenuOpen ? "max-h-64 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="py-4 space-y-2 border-t border-gray-200 dark:border-gray-800">
            {navItems.map((item) => (
              <a
                key={item}
                href={`/#${item.toLowerCase()}`}
                onClick={() => handleItemClick(item.toLowerCase())}
                className={`block px-4 py-2 text-sm font-medium transition-colors duration-200 rounded-lg
                  ${
                    activeSection === item.toLowerCase()
                      ? "text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20"
                      : "text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
                  }`}
              >
                {item}
              </a>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default function Portfolio() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  const googleDocDownloadLink =
    "https://docs.google.com/document/d/1otQIXp_XneHW8QlYUigY8UQuG_PWQTLniTTJ8nxGMQs/export?format=pdf";

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setIsDarkMode(true);
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`${isDarkMode ? "dark" : ""}`}>
      <div className="dark:bg-gray-950 bg-white min-h-screen transition-colors duration-300">
        \
        <NavBar
          toggleDarkMode={toggleDarkMode}
          isDarkMode={isDarkMode}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
        />
        <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16">
          <section className="text-center py-20">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent dark:from-blue-300 dark:via-purple-300 dark:to-pink-300 mb-6">
              Full Stack Developer
            </h1>
            <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8">
              Building elegant solutions to complex problems with modern
              technologies. Passionate about creating seamless user experiences
              and scalable systems.
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href={googleDocDownloadLink}
                download="Resume.pdf"
                className="max-sm:text-sm inline-flex items-center px-3 sm:px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
              >
                <Download className="h-5 w-5 mr-1 sm:mr-2" />
                Download Resume
              </a>
              <a
                href="#contact"
                className="max-sm:text-sm inline-flex items-center px-6 py-3 rounded-lg border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white hover:border-blue-600 dark:hover:border-blue-400 transition-colors duration-200"
              >
                <Mail className="h-5 w-5 mr-2" />
                Contact Me
              </a>
            </div>
          </section>

          {/* Projects Section */}
          <section className="py-16" id="projects">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
              Featured Projects
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {projects.map((project, index) => (
                <div
                  key={index}
                  className={`group p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-200 ${
                    index % 2 === 1 ? "justify-self-center" : ""
                  }`}
                >
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                    {project.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    {project.description}
                  </p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.tech.map((tech, techIndex) => (
                      <span
                        key={techIndex}
                        className="px-3 py-1 text-sm rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                  <div className="flex space-x-4">
                    {/* <a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-sm text-blue-600 dark:text-blue-400 hover:underline"
                    >
                      <Github className="h-4 w-4 mr-1" />
                      View Code
                    </a> */}
                    <a
                      href={project.demo}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-sm text-blue-600 dark:text-blue-400 hover:underline"
                    >
                      <ExternalLink className="h-4 w-4 mr-1" />
                      Visit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Skills Section */}
          <section className="py-16" id="skills">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
              Skills & Technologies
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {Object.entries(skills).map(([category, items]) => (
                <div
                  key={category}
                  className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-200"
                >
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    {category}
                  </h3>
                  <div className="space-y-2">
                    {items.map((skill, index) => (
                      <div
                        key={index}
                        className="flex items-center text-gray-600 dark:text-gray-300"
                      >
                        <ChevronRight className="h-4 w-4 mr-2 text-blue-600 dark:text-blue-400" />
                        {skill}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Experience Section */}
          <section className="py-16" id="experience">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
              Work Experience
            </h2>
            <div className="space-y-8">
              {experience.map((job, index) => (
                <div
                  key={index}
                  className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-200"
                >
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                    {job.role}
                  </h3>
                  <div className="flex items-center text-gray-600 dark:text-gray-300 mb-4">
                    <Coffee className="h-4 w-4 mr-2" />
                    {job.company}
                    <span className="mx-2">•</span>
                    {job.period}
                  </div>
                  <ul className="space-y-2">
                    {job.achievements.map((achievement, achievementIndex) => (
                      <li
                        key={achievementIndex}
                        className="flex items-start text-gray-600 dark:text-gray-300"
                      >
                        <ChevronRight className="h-4 w-4 mr-2 mt-1 text-blue-600 dark:text-blue-400 flex-shrink-0" />
                        {achievement}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          {/* Contact Section */}
          <section className="py-16" id="contact">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
              Get in Touch
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <a
                href="mailto:aryan@example.com"
                className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-200 group"
              >
                <div className="flex items-center mb-4">
                  <Mail className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Email
                  </h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
                  aryanthakore24@example.com
                </p>
              </a>
              <a
                href="https://github.com/aryan1982"
                target="_blank"
                rel="noopener noreferrer"
                className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-200 group"
              >
                <div className="flex items-center mb-4">
                  <Github className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    GitHub
                  </h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
                  github.com/aryan1982
                </p>
              </a>
              <a
                href="https://www.linkedin.com/in/aryan-thakor/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-200 group"
              >
                <div className="flex items-center mb-4">
                  <Linkedin className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    LinkedIn
                  </h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
                  linkedin.com/in/aryan
                </p>
              </a>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
